body > iframe {display: none;width: 0;height: 0;visibility: hidden;}

.graphicHead {
  text-align: center;
  padding-bottom: 20px;
}
.graphicHead h2 {
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  color: #231f20;
  padding: 0 100px;
  position: relative;
  text-transform: uppercase;
  margin: 0 10px;
  word-break: break-word;;
}
.graphicHead h2 {
  color: #FFFFFF;
}

.graphicHead h2:before,
.graphicHead h2:after {
  content: " ";
  width: 90px;
  height: 39px;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
  top:50%;
  margin-top: -18px;
}
.graphicHead h2:before {
  background-image: url(images/heading-left-new.png);
  left: 0;
}
.graphicHead h2:after {
  background-image: url(images/heading-right-new.png);
 right: 0;
}
.moreLink .p-button {
  font-weight: bold;
  color: #facb33;
  cursor: pointer;
  margin: 15px 0 0 0;
  font-size: 12px;
  background-color: transparent;
}
.moreLink .p-button.p-button-link:enabled:hover {
  color: #facb33;
}
.moreLink .pi {
  margin-left: 5px;
  transition: all 0.2s ease-in-out;
}

.moreLink:hover .pi {
  transform: translateX(5px);
}
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  border: 2px solid #aaa;
}
.p-carousel .p-carousel-content .p-carousel-prev .pi,
.p-carousel .p-carousel-content .p-carousel-next .pi {
  color: #aaa;
  font-weight: bold;
  font-size: 12px;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  background-color: transparent;
  border: 2px solid #aaa;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #a59157;
}
.p-dialog {
  border: solid 5px #755a34;
}
.p-dialog .p-dialog-header,
.p-dialog .p-dialog-footer {
  border-radius: 0;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  background-color: #ab8b38;
  color: #fff;
  opacity: 0.6;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  font-weight: bold;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  opacity: 1;
  background-color: #ab8b38;
  color: #fff;
}
/**Header**/
.navigation{height: 100%;margin: auto; max-width: 1240px;}
.navigation > .align-items-center{height: 100%;}
.mainHeader {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #1e5073;
  box-shadow: 0 6px 6px -6px #000;
  height: 80px;
  z-index: 50;
  border: 0;
  border-radius: 0;
  background-image: url(images/newGraphic.png);
  background-repeat: repeat no-repeat;
  background-position: 50%, 50%;
  background-size: 144px;
}
.mainHeader a {
  color: #fff;
  padding: 5px 15px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}
/* .mainHeader a:hover,
.mainHeader a.active {
  color: #a59157;
} */

.mainHeader a:hover {
  color: #a59157;
}

.hide {
  color: #0C3855;
}

.mainHeader .brand {
  background-image: url(images/logo.png);
  width: 190px;
  height: 90%;
  margin: 0 0 0 45px;
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999px;
}

.react-multi-carousel-dot--active button {
  background-color: #ab8b38;
}


/**Main Carousel**/
/* .mainCarousel {
  padding: 10px 0 10px 0;
} */
.promoSlide{
  position: relative;
  margin: 0px 0px -4px 0px;
  border:2px solid #ab8b38;
  /* box-shadow: 12px 0 15px -4px #ab8b38, -12px 0 8px -4px #ab8b38; */
  /* box-shadow: 0 2px 12px 0 #ab8b38; */
}
.mainCarousel .promoSlide img {
  display: block;
  max-height: 800px;
  margin: 0 auto;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
}

.mainCarousel .promoSlide h3 {
  font-size: 2em;
  text-align: center;
  padding: 35px 5px;
  margin: 0;
  font-weight: normal;
  position: absolute;
  z-index: 999;
  bottom: 0;
  color: #FFFFFF;
  width: 100%;
  background-image: linear-gradient(to bottom, rgb(12 94 134 / 0%), rgb(0 69 103));
}

/**Achievement starts**/
.TextureBg {
  background-image: url(images/textureBg.jpg);
  background-image: -webkit-image-set(url(images/textureBg.webp) );
  padding: 25px 0;
}
.visitPlacesArea{
  padding: 25px 0 0px;
  background-color: #FFFFFF;
}
.visitPlacesArea .react-multi-carousel-item{display: flex;justify-content: center;}
.achievementsCarousel.part2 {
  background: none;
}

.recognition {
  /* background: none; */
  background-color: #0C5E86;
  padding: 40px 0 80px 0;
}

.recognition .achievement-item{
  box-shadow: none;
}

.recognition .thumbImage{
  width: 260px;
  margin: 0 auto;
height: 250px;
  }
  .recognition .thumbImage .achievement-image{height: 100%;}
.recognition .achievement-item-content{
  background: none;
}

.recognition .thumbContent{
  border: 0;
  background-color: transparent;
}

.recognition .thumbContent textarea{
    color: #FFFFFF;
    font-size: 1.2em;
    height: 57px;
    padding: 0 10%;
    line-height: 20px;
    overflow: hidden;
    margin: 0;
}

.recognition .thumbImage img {
  object-fit: contain;
  /* transform: scale(0.8); */
  width: 100%;
  height: 100%;

}

.achievementsCarousel .p-carousel-items-content {
  margin: 0 auto;
}

.react-multi-carousel-dot-list {
  bottom: auto;
  padding:10px 5px;
  flex-wrap: wrap;
  top:auto;
  position: relative;
  width: 100%;
}

.recognition .p-image-preview-indicator {
  /* display: none !important; */
}

.react-multi-carousel-dot button {
  border-color: transparent;
  width: 15px;
  height: 15px;
  margin: 10px;
  border: 1px solid #ab8b38;
}

.achievementsCarousel .achievement-image {
height: 100%;
text-align: center;
width: 100%;
}
.achievementsCarousel .achievement-item {
  box-shadow: 0 2px 12px 0 #ab8b38;
  margin: 10px 40px;
  border-radius: 140px 140px 0 0;
  overflow: hidden;
}
.achievementsCarousel .react-multi-carousel-item{display: flex;justify-content: center;}
.achievementsCarousel .react-multi-carousel-item .achievement-item {width: 280px;}
.recognition .achievement-item {border-radius: 0;box-shadow: none;}
.recognition .react-multi-carousel-item .achievement-item{max-width: 100%;}
.recognition .react-multi-carousel-item{display: block;}
.achievementsCarousel .achievement-item-content {
  /* background-color: #fff; */
}
.achievementsCarousel .thumbImage {
  position: relative;
  height: 215px;
}
.achievementsCarousel .thumbImage img{
 width: 100%;
 object-fit: cover;
 height: 100%;
 margin: 0 auto;
}

.achievementsCarousel .arc img{
  /* border-top-right-radius: 247px;
  border-top-left-radius: 247px; */
 }

.achievementsCarousel .thumbContent {
background-color: #fff;
  padding: 20px 15px;
}
.achievementsCarousel .thumbContent h4 {
  color: #244575;
  font-size: 16px;
  font-weight: 800;
  padding: 0px 0 0px 28px;
  background-image: url(images/icon1.png);
  background-repeat: no-repeat;
  background-position: left 0;
  margin: 0 0 10px 0;
  /* display: -webkit-box; */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 22px;
}
.achievementsCarousel .thumbContent .p-disabled {
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  height: 80px;
  overflow: hidden;
}

.achievementsCarousel .carousel-container {
  /* max-width: 90%;
  margin: 0 auto;

  overflow: hidden; */
}

.achievementsCarousel.part2 .achievement-item {
  /* box-shadow: 0 1px 6px 0 rgb(0 0 0); */
  box-shadow: 0 2px 12px 0 #ab8b38;
}

/**About css**/
.aboutContainer {
  padding: 10px 0 50px 0;
  position: relative;
}


/**Activity container starts**/

.activitiesCarousel {
  background-color: #e8ecee;
  padding: 10px 0 50px;
}

.activitiesCarousel .imageContent {
  position: relative;
  padding: 10px;
  
}
.activitiesCarousel .imageContent img{display: block;width: 100%;}
.activitiesCarousel .imageContent:before,
.activitiesCarousel .imageContent:after {
  content: "";
  position: absolute;
  width: 36px;
  height: 36px;
  /* background-size: cover;
  background-image: url(images/frame-top.png); */
}
.activitiesCarousel .imageContent:before {
  top: 0;
  left: 0;
}
.activitiesCarousel .imageContent:after {
  transform: rotate(180deg);
  bottom: 0;
  right: 0;
}
.activitiesCarousel .textContent {
  /* padding: 20px 0 0 0; */
  text-align:center;
  
}

.activitiesCarousel .textContent h3 {
  color: #244575;
  font-size: 24px;
  line-height: 1;
  margin: 0 0 0 0;
  font-weight: normal;
}

.aboutus .customScroll{

}

.activitiesCarousel .textContent .p-disabled {
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  margin: 0;


}
.activitiesCarousel .p-scrollpanel-content{}
.activitiesCarousel  .p-scrollpanel-content{}
/**Event section**/
.eventCarousel {
  background-color: #f2f2f2;
  padding: 10px 0 50px;
}

.eventCarousel .carousel-container{
  justify-content: center;
  padding: 40px 0 0;
}

.eventCarousel {
  padding: 40px 0;
}

.eventCarousel .date{
  float: left;
  margin-right: 25px;
}

.eventCarousel .moreLink .p-button{
  margin-top: 0;
  padding: 0.25rem 0;
}
    
.eventCarousel .carousel-container {
  max-width: 90%;
  margin: 0 auto;
}

.eventTile {
  padding: 0 5px 40px 5px;
  position: relative;
}
.eventTileContent {
  margin: 0 10px;
  background-color: #fff;
  padding: 15px 15px 30px 15px;
  transition: all 0.3s ease-in-out;
  max-height: 470px;
}
.eventTileContent img{width: 100%;}
.eventTileContent h4 {
  color: #254576;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0 15px 0;
}

.eventTileContent .p-disabled {
  font-size: 12px;
  line-height: 18px;
  height: 80px;
  overflow: hidden;
}
.dateAndLoc .pi {
  color: #face3f;
  vertical-align: middle;
}
.dateAndLoc span {
  margin-left: 5px;
}
.dateAndLoc .location{
  margin-top:10px;
  height: 24px;
}
.eventTileContent .dateAndLoc span {
  font-size: 12px;
 
}
.eventTileContent .dateAndLoc .location span{
  white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
}


.eventTileContent:hover {
  -webkit-box-shadow: 0px 15px 35px -25px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 15px 35px -25px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 15px 35px -25px rgba(0, 0, 0, 0.75);
}
.p-dialog  .dateAndLoc .location span{word-break: break-word;}

/**Footer css**/
.FooterSection {
  /* background-color: #011728; */
  padding: 15px 0.5rem 0px 0.5rem;
  background-image: url(images/textureBg.jpg);
  background-image: -webkit-image-set(url(images/textureBg.webp) );
  display: flex;
  flex-wrap: wrap;
}
.footerLogo {
  background-image: url(images/smallLogo.png);
  width: 100px;
  height: 100px;
  background-size: cover;
  text-indent: -9999px;
}
.FooterSection h5 {
  color: #fff;
  /* padding: 5px 0 5px 28px;
  background-image: url(images/headIcon.png);
  background-repeat: no-repeat;
  background-position: left center; */
  margin: 0 0 10px 0;
  font-size: 14px;
  text-transform: uppercase;
}
.FooterSection ul {
  padding: 0;
}
.FooterSection ul li {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  margin: 0 0 5px 0;
  cursor: pointer;
}
.FooterSection .soicalLinks ul li {
  color: #b5a046;
}
.FooterSection .soicalLinks ul li .pi {
  font-size: 20px;
  cursor: pointer;
}

.FooterSection .footnote {
  color: #fff;
  font-size: 12px;
  margin: 0 0 5px 0;
}
.FooterSection address {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  padding: 0 28px 0 28px;
  font-style: normal;
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 30;

  transition: all 0.3s ease-in-out;
  visibility: hidden;
}
/* .overlay.showLayer {
  visibility: visible;
} */

/**Location page**/
.aboutLocation {
  position: relative;
  display: none;
}
.aboutLocation  textarea{
 color: #fff;
}
.locationCarousel{}
.locationSlide {
  position: relative;
  margin: 0px 0px -4px 0px;
}
.locationSlide img {
  display: block;
  width: 100%;
  max-height: 800px;
  margin: 0 auto;
  max-width: 100%;
  object-fit: cover;
}

.locationSlide .mazarName {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  padding: 15px;
  text-align: center;
}
.locationSlide .mazarName p {
  color: #766a46;
  font-size: 18px;
  padding: 5px 0 0;
  margin: 0;
}
.locationSlide .mazarName .pi {
  font-size: 24px;
  color: #fff;
}
.aboutLocation h3 {
  color: #fff;
  font-family: "Roboto", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.aboutLocation p {
  color: #fff;
  line-height: 1.5;
}
.aboutLocation .graphicHead h2 {
  color: #fff;
}

.part2 .graphicHead h2,
.aboutus .graphicHead h2,
.allLocations .graphicHead h2,
.tareekh .graphicHead h2,
.eventCarousel .graphicHead h2,
.visit .graphicHead h2{
  color: #0C3855;
}

.allLocations .graphicHead {
  padding: 0 0 50px;
}

.allLocations .searchField {
  width: 60%;
  margin: 0 auto 25px;
}

.allLocations .searchField .p-inputtext,
.allLocations .searchField .p-inputtext:enabled:hover {
  border-color: #A09265;
  border-radius: 25px;
    padding: 0.6rem 15px;
    background: url(images/search.png) no-repeat 97% 50%;
}

.p-inputtext,
.p-inputtext:enabled:focus,
.p-inputtext:enabled:hover {
  border-color: #A09265;
  /* border-radius: 25px; */
    /* padding: 0.6rem 15px; */
}

.p-dropdown:not(.p-disabled).p-focus{
  box-shadow: none;
}

.allLocations .searchField .p-float-label label{
  left: 17px;
  color: #c1b9b9;
}

.aboutus .graphicHead{
  padding: 30px 0;
}

.aboutus .p-button-label{
  display: none;
}

.aboutus .carouselWrapper .react-multi-carousel-list
{
  position: relative;
  height: 100%;
}

.aboutus .carouselWrapper .react-multi-carousel-list .slide{
  height: 100%;
}


figcaption div {
  height: 25px;
  display: flex;
  justify-content: center;
}

figcaption i {
  display: none !important;
}

figcaption div span {
  /* display: inline-block; */
  /* margin: 0px 16px 0px 0px; */
}

.allLocations .locationThumb figure figcaption{
  text-transform: uppercase;
    font-weight: 600;
    background: #0C3855;
}

.footerLogo {
  background-image: url(/static/media/logo.d9085298.png);
  background-position: 50%;
  background-repeat: no-repeat;
  width: auto;
  height: 90px;
  background-size: contain;
  text-indent: -9999px;
  margin: 0 auto;
  /* margin: 30px 0 0 30px; */
}

.FooterSection ul li {
  font-weight: 300;
}

.FooterSection h5{
  font-weight: 600;
}

.footnote {
  font-size: 14px;
}

.FooterSection .pi-instagram::before,
.FooterSection .pi-youtube::before {
  content:"";
}

.FooterSection .pi-youtube{
  background: url(images/yt_icon.png);
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
}

.FooterSection .pi-instagram{
  background: url(images/insta_icon.png);
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
}

.FooterSection .pi-youtube{
  background: url(images/yt_icon.png);
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
}

.graphicBottom h2{
  display: inline-block;
  background: #FACB33;
  font-size: 1.1em;
  padding: 10px 35px;
  margin: 30px 0 0;
  border-radius: 25px;
  cursor: pointer;
}

.mazarLogo,
.graphicBottom {
 text-align: center;
}
.mazarLogo img{
  width: 100px;
  height: 100px;
  display: inline-block;
  border-radius: 50%;
}
.mazarLogoTab img{
  width: 53px;
  height: 53px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
}
.mazarname {
  color: #071f3d;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  /* font-weight: normal; */
}
.tabsSection {
  background-color: #FAFAFA;
  padding: 17px 0;  
}

.locationPge {
  position: sticky;
  top: 80px;
  z-index: 11;
}

.locationCarousel {
  position: relative;
}

.locationCarousel .tabsSection {
  padding: 20px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: rgba(0,0,0,0.5);
}

.locationCarousel .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: gray;
  font-weight: normal;
}

.locationCarousel .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #FFFFFF;
}

.p-tabview-panels{
  display: none;
}

.middleContainer.eventpage {
  background: #E8ECEF;
  /* margin-top: 80px; */
}
.eventpage .achievementsCarousel .achievement-item{margin: 10px 62px 40px;}
.middleContainer.locationglry {
  background: #E8ECEF;
  /* margin-top: 80px; */
}

.locationglry .container {
  margin-top: 17px;
  /* margin-bottom: 24px; */
}

.aboutLocation .container {
  margin-top: 0;
}

.container.tareekh {
  max-width: none;
  background: #FFFFFF;
  /* padding-bottom: 50px; */
}

.container.announcement{
  margin-top: 0;
  padding-top: 17px;
  background-color: #f1f2f6;
}

.Update {
  color: #766a46;
  margin: 0 0 0 0;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  align-items: center;
  border: 0;
  display: flex;
  list-style-type: none;
  margin-bottom: 20px;
  justify-content: center;
  padding-left: 0;
}

.Update li{
  padding: 0 20px 10px 20px;
  margin-bottom: 3px;
  opacity: 0.75;
}

.Update li.active{
  margin-bottom: 0;
  border-bottom: 3px solid;
  opacity: 1;
}

.container.tareekh .innerContent{
  max-width: 1140px;
  width: 100%;
}

.container.eventpage {
  max-width: 1314px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.tareekh .graphicHead {
  /* padding: 50px 0; */
  padding-top: 10px;
}

.visitArea{


}

.visitArea {
  width: auto;
    background: #F1F2F6;
    padding: 2px 18px 0px;
    margin: 0px 12px;
    /* background: url(images/location.png) no-repeat 100% 95%;
    background-position-x: 40px;
    background-size: 25px; */
    background-color: #F1F2F6;
    margin: 10px 5px;
    position: relative;
    box-shadow: 0 0 7px 0 grey;
}

.placeWrapper .visitArea .imageBox{
  width: 76px;
  height: 76px;
}

.placeWrapper .visitArea .imageBox img{
  width: 76px;
  height: 76px;
}

.placeWrapper .visitContent {
  max-height: 315px;
  overflow: hidden;

}

.visitPlacesArea .carouselWrapper .react-multiple-carousel__arrow--left{left: -4px;}
.visitPlacesArea .carouselWrapper .react-multiple-carousel__arrow--right{right:-4px}

.p-button.viewMoreBtn,
.p-button.viewMoreBtn.p-button-link:enabled:hover {
    background: #FACB33;
    font-size: 1.1em;
    padding: 10px 35px;
    margin: 30px 0 0;
    border-radius: 25px;
    cursor: pointer;
    position: absolute;
    color: #444;
    display: none;
}

.thumbImage:hover button,
.p-button.viewMoreBtn.p-button-link:enabled:hover {
  display: inline-block;
}

.viewMoreBtn .p-button-icon {
  display: none;
}


.p-tabview .p-tabview-panels,
.p-tabview .p-tabview-nav,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: transparent;
}
.p-tabview-nav {
  justify-content: center;
}
.p-tabview .p-tabview-nav {
  position: relative;
  align-items: center;
  border: 0;
}
.p-tabview .p-tabview-nav:before,
.p-tabview .p-tabview-nav:after {
  content: "";
  position: relative;
  width: 89px;
  height: 38px;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
}
.p-tabview .p-tabview-nav:before {
  margin-right: 5px;
  background-image: url(images/heading-left-new.png);
}
.p-tabview .p-tabview-nav:after {
  margin-left: 5px;
  background-image: url(images/heading-right-new.png);
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: #8f8f8f;
  text-transform: uppercase;
  padding: 0;
  border: 0;
  margin: 0 10px;
}
.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  background-color: transparent;
  border: 0;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #071f3d;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #facb33;
}
.newTabHeader{
  display: flex;
  margin: 0;
  padding: 0;
  flex: 1 1 auto;

}
.newTabHeader a {
  color: #8f8f8f;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  line-height: 1;
  white-space: nowrap;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
.newTabHeader a:hover {
  color: #6c757d;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  line-height: 1;
  white-space: nowrap;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
.headerArt{
  position: relative;
}
.headerArt:before , .headerArt:after {   
   content: "";
  position: relative;
  width: 89px;
  height: 38px;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;}
.headerArt:before {
  margin-right: 5px;
  background-image: url(images/heading-left-new.png);
}
.headerArt:after {
  margin-left: 5px;
  background-image: url(images/heading-right-new.png);
}

.innerContent {
  max-width: 1240px;
  margin: 0 auto;
}
.contentBox {
  background-color: #fff;
  padding: 25px;
  position: relative;
  height: auto;
}
.rightContentBox.contentBox {
  /* max-height: 500px; */
  text-align: center;
}
.contentBox h3,
.eventSection h3 {
  color: #766a46;
  margin: 0 0 0 0;
  font-size: 20px;
  font-weight: 600;
}
.contentBox textarea {
  color: #756623;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.3em;

}

.placeWrapper{margin: 0px 10px 0;width: 350px;}
.placeWrapper .imageBox {
  width: 52px;
  height: 52px;


  margin-right: 10px;
}
.placeWrapper .imageBox img{border-radius: 50%;  width: 52px;
  height: 52px;}
.placeWrapper .descriptionBox{
  /* width: 100%; */
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  height: 234px;
  /* overflow: hidden; */
  font-size: 14px;
  line-height: 1.5;
}
.placeWrapper .descriptionBox h4 {
  padding: 0;
  margin: 0;
}

.placeWrapper .descriptionBox p {
}
.announcementSection .p-disabled{font-size: 16px;line-height: 1.5;color: #444;}
.announcement .contentBox{ height:100%;}
.announcement  .customScroll{height: 300px;}

.announcement .react-multiple-carousel__arrow--left{left: -7px;}
.announcement .react-multiple-carousel__arrow--right{right:-7px}

.crownBg {
  background-image: url(./images/boxGraphic-new.png), url(./images/boxGraphic-new.png),
    url(./images/boxGraphic-new.png), url(./images/boxGraphic-new.png);
  background-position: -40px -40px, right -40px top -40px,
    left -40px bottom -40px, right -40px bottom -40px;
  background-repeat: no-repeat;
  border: solid 1px #ae904d;
}
.viewPdfBtn {
  position: absolute;
  top: 16px;
  right: 45px;
  width: 32px;
  height: 27px;
  background: url(./images/viewPdf.png) no-repeat;
  cursor: pointer;
}

.rightContentBox .customScroll {
  width: 100%;
  max-height: 400px;
}
.rightContentBox.h-full .customScroll { 
  /* height:600px; */
}
.leftContentBox .customScroll {
  width: 100%;
  height: 330px;
}

.customScroll .p-scrollpanel-wrapper {
  /* border-right: 9px solid #f4f4f4; */
}

.customScroll .p-scrollpanel-bar {
  background-color: #a59157;
  opacity: 0.3;
  transition: background-color 0.3s;
}

.customScroll .p-scrollpanel-bar:hover {
  background-color: #135ba1;
}

.QRwrapper {
  margin: 10px 0 0 0;
  background-color: #f4f8fb;
  padding: 10px;
  
}

.activitiesCarousel .grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}
.QRwrapper .p-disabled, .QRwrapper .p-component:disabled {
  color: #838586;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;

  line-height: 1;
}
.QRwrapper img {
  display: block;
  width: 100%;
}
.eventSection h3 {
  margin: 30px 0 20px 0;
}
.eventSection .eventTile {
 
}
.eventSection .eventTile .eventTileContent {
 
}
.peakPeriod {
  background-color: #fff;
  padding: 25px 15px 55px 15px;
}
.peakPeriod h3 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 0px;
}
.peakPeriod .chips {
  margin: 0 10px 15px 0;
  font-size: 14px;
  background: #E7ECEF;
    padding: 10px 25px;
    border-radius: 40px;
   display: inline-block;
}

.tabHeaderLogo {
  position: absolute;
  width: auto;
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
  display: flex;
}

.peakPeriod .graphicHead{
  padding-bottom: 30px;
}
.mazarContact p {
  color: #495057;
  margin: 0 0 0 0;
}
.feedbackBtn {
  padding: 40px 0 0;
}

.mapContainer iframe {
  border: 0px;
  width: 100%;
}
.bankIcon {
  border: 1px solid #000;
  padding: 10px;
  border-radius: 50%;
}
.bankIcon:before {
  font-size: 24px;
}
.mobileAccordion {
 
  display: none;
}

.allLocations{
  background-color:#FAFAFA;
  padding: 10px 0 50px 0;
}
.locationThumb{position: relative;}
.locationThumb figure {
  position: relative;
  /* cursor: pointer; */
  transition: all 0.3s ease-in-out;
  padding: 0;
  margin: 0;
  height: 225px;
  align-items: normal !important;
}
.locationThumb figure:hover {
  box-shadow: 0px 15px 35px -25px rgb(0 0 0 / 75%);
}
.locationThumb figure:hover figcaption i {
  transform: translateX(10px);
}

.locationThumb figure a {
  width: 100%;
}

.locationThumb figure img {
  width: 100%;
  max-height: 100%;
  display: block;
  object-fit: cover;
  margin: 0 auto;
}
.locationThumb figure figcaption {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: rgba(1, 0, 34, 0.7);
  padding: 10px;
  font-size: 15px;
  color: #fff;
  text-align: center;
}
.locationThumb figure figcaption i {
  color: #facb33;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.mapWrapper{background-color: #E7ECEF;padding: 10px 0 50px 0;}
.mapWrapper iframe{width: 100%;border: 0;}

.calendarWrapper{
  background-color: #011728;
  padding: 10px 0 50px;
}

.calendarWrapper h2{
  color:#FFCA00
}
.pdfAndFeedback{padding: 10px 0 50px;}
.pdfIcon img{width: 70px; cursor: pointer;}
.feedbackIcon img{width: 70px;}


.mazarContact .socialLinks{margin: 0;padding: 0;list-style: none;}
.mazarContact .socialLinks li{display: inline-block;margin: 5px 5px;}
.mazarContact .socialLinks li span{display: block;cursor: pointer;} 
.mazarContact .socialLinks li .pi{margin-right: 5px;vertical-align: middle;}
.galleryWrapper {
 
  margin: 0 0 10px 0;
}
.galleryWrapper figure img {
  max-height: 285px;
}
.galleryWrapper .carouselWrapper{
  width: 100%;
  margin: 0 auto;
}

.galleryThumbs {
  text-align: center;
}

.galleryThumbs label {
    border: 1px solid lightgray;
    padding: 15px 15px 15px 60px;
    font-size: 14px;
    font-weight: bold;
    color: #766a46;
    display: inline-block;
    text-align: right;
    border-radius: 25px;
    margin: 10px;
    position: relative;
}

.galleryThumbs img {
  width: 47px;
  height: 47px;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px;
  border-radius: 25px;
}

  .galleryThumbs label:hover
  {
    border-color: #f9c119;
    color: #f9c119;
  }
.galleryWrapper .carousel-container{margin:0 auto;overflow: hidden;}
.galleryWrapper .carouselWrapper .react-multiple-carousel__arrow--left{left: 0;}
.galleryWrapper .carouselWrapper .react-multiple-carousel__arrow--right{right:0}
.galleryTab .carouselWrapper{
  width: 80%;
  margin: 0 auto;
}
.galleryTab .carouselWrapper .carousel-container {width: 90%;margin: 0 auto;}
.galleryTab .carouselWrapper .react-multiple-carousel__arrow--left{left: 0;}
.galleryTab .carouselWrapper .react-multiple-carousel__arrow--right{right: 0;}
.gallerySlide {height:600px;overflow: hidden;}
.gallerySlide h3{margin: 0 0 20px 0;text-align: center;}
.gallerySlide img{    
  margin: 0 auto;
  display: block;
  max-width: 100%;
  object-fit: contain;
  height:600px;
  border-radius: 25px 25px 25px 25px;}
.galleryTab .gallerySlideTab img{display: block;margin: 0 auto;}
.gallerySlideTab{position: relative;min-height: 385px;}
.gallerySlideTab .p-image-preview-container{width: 100%;}
.weatherAndMisc{
  padding: 15px;
  background-color:rgba(0, 0, 0, 0.75) ;
}

.miscTimeBtn.p-button.p-button-link,
.miscTimeBtn.p-button.p-button-link:hover{color: #fff;text-decoration: none;}
.miscTimeBtn .pi{color: #facb33;}

.weatherWrapper .rw-container-main{display: none;}
.weatherWrapper .rw-container{box-shadow: none;}
.weatherWrapper .rw-forecast-days-panel{background-color: transparent;}
.weatherWrapper .rw-forecast-date,
.weatherWrapper .rw-forecast-desc,
.weatherWrapper .rw-forecast-range{
  color:#fff;
}

/* .achievementsCarousel .react-multi-carousel-list {
  justify-content: center;
} */

.achievementsCarousel .pi-eye:before {
  content: "";
}

/* .achievementsCarousel .moreLink {
  display: none !important;
} */

.editContact{
  left: 30px;
}
.headerDropdown{
  background: transparent !important;
  border: none !important;
    padding: 0px 0px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-size: 16px;
  padding-right: 0px;
}
.p-dropdown .p-dropdown-label.p-placeholder:hover {
  font-family: "Roboto", sans-serif;
  color: #a59157;
  font-size: 16px;
  padding-right: 0px;
}
.p-dropdown .p-dropdown-trigger {
  /* background: transparent;
  color: #fff;
  width: 3rem; */
  display: none;
}
.p-connected-overlay-enter-done{
  z-index: 49 !important;
  margin-top: 18px;
}
/* .p-dropdown .p-dropdown-trigger:hover {
  background: transparent;
  color: #a59157;
  width: 3rem;
} */
.p-image-preview-indicator
{
  /* border-radius: 18px; */
}
/* h3.viewPdfIcon { 
  padding-right:24px;  
  background:url(./images/viewPdf.png) no-repeat 100% 50%; 
  cursor: pointer;
} */

.viewPdfIcon h3 span {
  display:inline-block;
  top: 16px;
  right: 45px;
  width: 32px;
  height: 27px;
  background:url(./images/viewPdf.png) no-repeat;
  margin:0 0 0 5px;
  overflow:hidden;
}
.past{
  color: #244575;
    font-size: 13px;
    font-weight: bold;
}
.upcoming{
  color: #387524;
    font-size: 13px;
    font-weight: bold;
}
.cursor{cursor: pointer;}


.recognitionTitle{
  color: #FFFFFF;
  text-align: center;
}
.h100{
  height: 100%;
}

.carouselWrapper.carouselPopUp{margin-bottom: 30px;}
.blink_me {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.arc{
  /* border-top-right-radius: 247px;
  border-top-left-radius: 247px;
  border: solid;
    border-width: 1px;
    border-color: #ab8b38; */
}
.achievementsCarousel .arc img{
  /* border-top-right-radius: 247px;
  border-top-left-radius: 247px; */
 }

 .arc .p-image-preview-indicator {
  /* border-top-right-radius: 247px;
  border-top-left-radius: 247px; */
}
.active1 {
  color: #071f3d !important;
  text-decoration: underline;
  text-decoration-color: #facb33;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
}
.placeWrapper .visitContent .coverImage{ 
  width: 76px;
  height: 76px; 
  float: left; 
  margin: 0 15px 0 0;   
  border-radius: 50%;
  display: inline-flex;
  overflow: hidden;
}
.coverImage img
{
  object-fit: cover;
  width: 100%;
  border-radius: 50%;
display: block;
     
 
}


/* ribbon */
.ribbon {
  width: 70px;
  height: 70px;
  overflow: hidden;
  position: absolute;
  top:-11px;
  right: -11px;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 100px;
  padding: 1px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 14px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -11px;
  top: 25px;
  transform: rotate(45deg);
}
#navbar2{
  transition: top 1s;
}
.navDropdown {
  display: none;
}
.bottomNavbar {
  display: none;
}
.p-scrolltop {
  position: fixed;
  bottom: 75px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-dialog .p-dialog-header .p-dialog-header-icon{
  -webkit-tap-highlight-color: transparent;
}
input,
textarea,
button,
select,
li,
div,
a {
    -webkit-tap-highlight-color: transparent;
}
.hideReadmore{
  display: none;
}
.announcementIcon
{
  margin: 0;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom;
  color: #ffffff;
  background: #8c75f3;
  height: 3rem;
  width: 3rem;
  border-radius: 2rem;
  justify-content: center;
}
.dateAndLoc{
  font-size: 12px;
  font-weight: bold;
}
.mobileAbout{
  display: none;
}
.desktopAbout{
  display: block;
}

/* body {
  overflow-x: hidden;
  width: 100%;
} */

/* Location Marker */

 .markercontainer {
	 /* top: 200px;
	 left: 50%;
	 width: 32px;
	 height: 28.8px; */
	 position: relative;
   cursor: pointer;
}
 .marker {
	 /* z-index: 1;
	 position: absolute;
	 top: 10%;
	 left: 50%;
	 margin-left: -9px;
	 width: 18px;
	 height: 18px;
	 border-radius: 18px;
	 background-color: #bf9f55;
	 animation: hover 1s infinite ease-in; */

   position: absolute;
   left: 10%;
   /* bottom: 1%; */
   margin-left: -9px;
   width: 18px;
   height: 18px;
   border-radius: 50% 50% 50% 0;
   background: #bf9f55;
   transform: rotate(-45deg);
   animation: hover 1s infinite ease-in;
   /* margin: -20px 0 0 -20px; */
   
}
 .marker:after {
	 /* content: '';
	 z-index: 2;
	 position: absolute;
	 top: 50%;
	 left: 50%;
   margin-left: -5px;
   margin-top: -4.25px;
   width: 9.5px;
   height: 9.5px;
   border-radius: 7.5px;
	 background-color: #0c3855; */
    left: 23%;
    bottom: 23%;
    content: "";
    width: 10px;
    height: 10px;
    /* margin: 8px 0 0 8px; */
    background: #0c3855;
    position: absolute;
    border-radius: 50%;
}
 /* .marker:before {
	 z-index: 0;
	 content: '';
	 position: absolute;
	 top: 80%;
	 left: 50%;
   margin-left: -7.2px;
	 border-left: 7.2px solid transparent;
	 border-right: 7.2px solid transparent;
	 border-top: 9px solid #bf9f55;
} */
 .pulse {
	 position: absolute;
	 top: 30%;
	 left: 50%;
	 display: block;
	 width: 18px;
	 height: 5.1428571429px;
	 background: skyblue;
	/* background alternative white */
	 border-radius: 50%;
	 transform: translate(-50%, 400%);
}
 .pulse:before, .pulse:after {
	 content: '';
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 width: 100%;
	 height: 100%;
	 background: skyblue;
	 border-radius: 50%;
	 opacity: 0.5;
	 transform: translate(-50%, -50%) scale(1);
	 animation: ripple 1s infinite ease-in;
}
 @keyframes ripple {
	 0% {
		 transform: translate(-50%, -50%) scale(1);
		 opacity: 0.5;
	}
	 100% {
		 transform: translate(-50%, -50%) scale(2);
		 opacity: 0;
	}
}
 @keyframes hover {
	 0% {
		 margin-top: 0px;
	}
	 50% {
		 margin-top: 7px;
	}
}

.topSpace{
  background-color: #1e5073;
  height: 80px;
}

.marquee-container{
  position: absolute !important;
}
.marqueetext {
  color: #ffbb00;
  font-size: 1.2em;
  position: absolute;
  top: 80px;
  z-index: 1;
  background: #0C3855;
  padding: 2px;
  font-weight: 300;
}


.profileImage,
.profileName{
  display: none;
}
.profileDropdown{
  right: 40px;
  color: #fff;
  position: absolute;
}
.dropdownVisible{
  display: none !important;
}
.dropdownLink{
  display: grid;
  position: absolute;
  background-color: #1e5073;
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  padding: 12px 16px;
  z-index: 500;
  right: 0px;
  top: 79px;
  text-align: center;
  border-radius: 0 0 14px 14px;
  transition: all 1.3s ease 0s;
}
.dropdownLink span{
  display: grid;
  color: #c9d5d6;
  font-size: 15px;
  font-weight: bold;
}
.dropdownLink a{
  max-width: 100% !important;
  font-size: 15px;
}
.login{
  right: 40px;
  color: #fff;
  position: absolute;
  font-size: 1.2em;
  cursor: pointer;
}
.developer{
  color: #fff;
  text-decoration: none;
  font-weight: 500;
}
.pointer{cursor: pointer;}
.p-image-preview-container:hover>.p-image-preview-indicator {
  background-color: transparent;
}
/* .p-image-preview-indicator {
  color: #755a34 !important;
} */
.p-image-preview-icon {
  font-size: 1.5rem;
  background-color: rgba(0,0,0,.5);
  /* color: #755a34; */
  padding: 10px 10px 10px 10px;
  border-radius: 50%;
}

.imagePlaceholder{
  height: 400px;
  display: block;
}

.boxHeight{
  height:400px
}

/* Primeflex classes */
.flex {
  display: flex!important;
}
.align-items-center {
  align-items: center!important;
}
.justify-content-end {
  justify-content: flex-end!important;
}
.justify-content-center {
  justify-content: center!important;
}
.w-full {
  width: 100%!important;
}
.h-full {
  height: 100%!important;
}
.p-inputtext {
  margin: 0;
}
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}
.text-center {
  text-align: center!important;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-fixed {
  flex: 0 0 auto;
  padding: 0.5rem;
}
.col-1 {
  width: 8.3333%;
}
.col-2 {
  width: 16.6667%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.3333%;
}
.col-5 {
  width: 41.6667%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.3333%;
}
.col-8 {
  width: 66.6667%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.3333%;
}
.col-11 {
  width: 91.6667%;
}
.col-12 {
  width: 100%;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 1rem!important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 2rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem!important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 2rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mr-3 {
  margin-right: 1rem!important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.mr-5 {
  margin-right: 2rem !important;
}
.mr-auto {
  margin-right: auto !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-3 {
  margin-left: 1rem!important;
}
.ml-4 {
  margin-left: 1.5rem !important;
}
.ml-5 {
  margin-left: 2rem !important;
}
.ml-auto {
  margin-left: auto !important;
}
