/**Edit section**/
.product-item {
    position: relative;
  }
  .controlButtons {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }
  .editPopHead {
    font-size: 20px;
    text-transform: uppercase;
    margin: 20px 0 5px 0;
  }
  
  .eventTile .controlButtons {
    top: auto;
    right: auto;
    bottom: 40px;
    left: 25px;
  }
  .locationSlide .controlButtons{top:95px}

  .placeWrapper .controlButtons .p-button.p-button-icon-only.p-button-rounded{
    width: 25px;
    height: 25px;
    padding: 5px;
    margin: 0 5px;}
  .placeWrapper .controlButtons .p-button.p-button-sm .p-button-icon{font-size: 12px;}
  .placeWrapper  .visitContent{position: relative;padding: 30px 0 0 0;}

 .historyBlock .p-button.p-button-icon-only.p-button-rounded{
    width: 25px;
    height: 25px;
    padding: 5px;
    margin: 0 5px;}
.historyBlock .p-button.p-button-sm .p-button-icon{font-size: 12px;}
  .historyBlock{
      position: relative;
      padding: 26px 0 0 0;
  }

  .historyBlock .controlButtons{top: 12px;}
  .historyBlock.historyBlockTitle .controlButtons{top: 0;left: 0;right: auto;}
  .editContact{position: relative;}
  .editContact .controlButtons .p-button{
      margin: 0 5px;
  }

  .peakPeriod .editChips .chips{margin: 0;}
  .editChips{position: relative;display: inline-block;margin: 10px 10px 10px 0;}
  .editChips .controlButtons{height: 100%;width: 100%;}
  .editChips .controlButtons .p-button.p-button-icon-only.p-button-rounded{width: 1rem;height: 1rem;
    padding: 5px;margin: 0;}
  .editChips .controlButtons .p-button.p-button-sm .p-button-icon{font-size: 8px;}
  .editChips .controlButtons .p-button.p-button-icon-only.p-button-rounded.p-button-danger{margin-top: auto;margin-left: auto;}
  .miscBtnEdit{position: relative;}