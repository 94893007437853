
/* @media only screen and (min-width: 1025px) and (max-width: 1280px){
  .aboutLocation{display: block;}
} */
@media only screen and (max-width: 1280px){
  .aboutLocation{display: block;}
  .tabHeaderLogo{display: none !important;}
  .logout{
    right : 0px !important;
  }
}

@media only screen and (max-width: 991px) {
  .imagePlaceholder{
    display: block;
    height:400px;
  }
  .mainCarousel .promoSlide img {
    display: block;
    max-height: 800px;
    margin: 0 auto;
    max-width: 100%;
    object-fit: cover;
    width: 100%;
    height:400px;
  }
  .locationSlide img {
    display: block;
    width: 100%;
    max-height: 800px;
    margin: 0 auto;
    max-width: 100%;
    object-fit: cover;
    height:400px;
  }
  .p-connected-overlay-enter-done{
    z-index: 49 !important;
    margin-top: 22px;
  }
.logoutD{
  display: none;
}
.profileDropdown{
  display: none;
}
.dropdownLink{
  display: none;
}
.scrolled{
  top:-80px;
}
  .navigation > div {
    justify-content: center !important;
}
  .navigation {
    background-color: #1e5073;
    /* transition: all 0.3s ease-in-out; */
    max-height: 0;
    overflow: hidden;
    transition: all .6s ease 0s;
    text-align: center;
  }
  .navigation.on {
    max-height: 1000px;
    height: 600px;
    text-align: center;
    border-radius: 0 0 38px 38px;
    transition: all 1.3s ease 0s;
  }
  .mobileLogo{
      background-image: url(/static/media/logo.d9085298.png);
    width: 114px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    /* margin: 17px 0px 0px -4px; */
    cursor: pointer;
    align-self: center;
  }
  .mainHeader a {
    display: block;
    /* border-bottom: 1px solid #232124; */
    padding: 15px;
    color: #fff;
    max-width: 40%;
    text-decoration: none;
  }
  .mainHeader .brand {
    display: none;
  }
  .hamburgerWrapper {
    /* padding: 13px 15px; */
    position: relative;
    height: 100%;
  }
  .alignCenter{
    text-align: -webkit-center;
  }
  .navDropdown{
    position: absolute;
    right: 50px;
    top:15px;
    cursor: pointer;
    display: block;
  }
  .hamburger{
    position: absolute;
    right: 15px;
    top:20px;
    cursor: pointer;
  }
  .eventBack{
    color: #fff !important;
    font-size: 15px;
  }
  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: #b5a046;
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 5px;
  }
  
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-8px, 6px);
    transform: rotate(-45deg) translate(-8px, 6px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-9px, -8px);
    transform: rotate(45deg) translate(-9px, -8px);
  }
  
  /**/
  /* .promoSlide{height: 400px;} */
  /* .mainCarousel .promoSlide img{max-height:350px;} */
  .footerLogo {
    margin: 0 auto;
  }
  /* .tabsSection{
    display: none;
  } */

  .mobileAccordion {
    background-color: #e9ecf1;
    padding: 25px 15px;
    display:none;
  }
  .mobileAccordion .p-accordion .p-accordion-content {
    background-color: transparent;
  }
  .activitiesCarousel{
    padding:10px 10px 25px ;
  }
  .QRwrapper{height: auto;}
  .gallerySlideTab{min-height:500px;}
  .galleryTab .carouselWrapper{width: 100%;}
  .galleryTab .carousel-container{width:90%;margin:0 auto;}
  .contentBox{padding: 15px;}
  .galleryWrapper .carousel-container{width: 100%;}
  .rightContentBox.contentBox{height: auto;}
  .p-accordion .p-accordion-content{padding: 1.25rem 0;}
  .galleryTab .carouselWrapper .carousel-container{width:100%;padding-top:25px;}
  .galleryTab .react-multiple-carousel__arrow--left, 
  .galleryTab .react-multiple-carousel__arrow--right{top:0}
  .locationPge{display: none;}
  .aboutLocation {display: none;}
  .bottomNavbar {
    background-color: #071f2a;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: block;
    transition: top 0.3s;
    z-index: 1001;
    justify-content: center;
    flex-direction: column;
    border-top: solid 1px #a59157;
  }
  
  .bottomNavbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 6px;
    padding-left: 10px;
    padding-bottom: 12px;
    /* height: 45px; */
    text-decoration: none;
    font-size: 2vmin;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 900;
    padding-top: 25px;
    margin-top: -20px;
  }
  
  .bottomNavbar a:hover {
    /* background-color: #ddd; */
    color: #a59157;
  }

  .bottomNavbar img{
    width: 55px;
    height: 55px;
    display: inline-block;
    border-radius: 50%;
    margin: -33px 1px 0 0px;
    background: #071f2a;
    border: solid 1px #a59157;
  }
  .bottomNavbar .p-image-preview-indicator{
        /* border-radius: 18px; */
        width: 55px;
        height: 55px;
        /* display: inline-block; */
        border-radius: 50%;
        margin: -33px 1px 0 0px;
  }
  .bottomNavbar .p-image-preview-container{
    width: fit-content;
  }

  .active2 {
    color: #a59157 !important;
  }
  .grid{margin: 0;}
  .FooterSection {
    padding-bottom: 100px;
   
  }

  .marqueetext {
    }

  .hideDropdown{
    display: none !important;
  }
 
  .navFont,
  .p-dropdown .p-dropdown-label.p-placeholder{
    font-size: 16px;
  }

.Update {
  font-size: 15px;
}
.galleryThumbs img {
  width: 34px;
  height: 34px;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px;
  border-radius: 25px;
}
.galleryThumbs label {
  border: 1px solid lightgray;
  padding: 10px 10px 10px 50px;
  font-size: 11px;
  font-weight: bold;
  color: #766a46;
  display: inline-block;
  text-align: right;
  border-radius: 25px;
  margin: 7px;
  position: relative;
}
.gallerySlide {
  height: 64vw;
}
.gallerySlide img{    
  margin: 0 auto;
  display: block;
  max-width: 100%;
  object-fit: contain;
  height:64vw;
  border-radius: 25px 25px 25px 25px;}
.profileImage{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 5px;
  display:block;
}
.profileName{
  text-decoration: none;
  padding-bottom: 15px;
  color: #c9d5d6;
  font-weight: bold;
  display:block;
}

}

@media only screen and (max-width: 768px) {

  /* .graphicHead h2{padding:0 70px;font-size: 18px;}
  .graphicHead h2:before, .graphicHead h2:after{width: 60px;height: 25px;margin-top:-12px;} */
  .mainHeader a {
    /* border-bottom: 1px solid #232124; */
    }
  .announcement .col-1 {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 100%;
      text-align: center;
   }
   .editContact {
    left: 0px;
  }
  .mobileAbout{
    display: block;
  }
  .desktopAbout{
    display: none;
  }
  .eventpage .achievementsCarousel .achievement-item {
    margin: 10px 26px 40px;
  }
}

@media only screen and (max-width: 576px) {
    /* mobile screen starts */
    .mobileAnnouncement{
      height: 250px;
      overflow: hidden;
    }
    .graphicHead h2{
      padding: 0 70px;
      font-size: 16px !important;
    }
    .tareekh .graphicHead {
      padding: 18px 0;
      /* padding-bottom: 20px; */
    }
    .contentBox h3,
    .eventSection h3 {
      font-size: 15px;
      color: #766a46;
      margin: 0px 0px 0px 0px;
      font-weight: 600;
      line-height: 24px;
    }
    .graphicHead h2{
      padding: 0 70px;
      font-size: 16px !important;
    }
    .graphicHead h2:before, .graphicHead h2:after{width: 60px;height: 26px;margin-top:-12px;}
    .hideReadmore{
      display: inherit;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
      font-weight: 700;
      font-size: .9rem;
    }
    .p-component{
      font-size: 0.8rem;
    }
    .announcementSection .p-disabled {
      font-size: 12px;
      line-height: 1.5;
      color: #444;
    }
    .dateAndLoc .location {
      margin-top: 10px;
      height: 34px;
    }
    .recognitionTitle{
      font-size: 18px;
    }
    .mainCarousel .promoSlide h3{
      font-size: 1.17em
    }
    .recognition .thumbContent textarea{
      font-size: 14px;
    }
    .eventpage .achievementsCarousel .achievement-item {
      margin: 10px 62px 40px !important;
  }
}

@media only screen and (max-width: 500px) {
  .locationThumb figure{height: 160px;}
  .bottomNavbar a {
    /* padding: 4px !important; */
    padding-left: 7px !important;
    font-size: 2.1vw !important;
    padding-bottom: 12px !important;
  }
  /* .graphicHead h2{
    padding: 0 70px;
    font-size: 16px !important;
  }
  .tareekh .graphicHead {
    padding: 18px 0;
  } */
}

@media only screen and (min-width: 576px)
{
  .sm\:block {
    display: block!important;
  }
  .sm\:col-1, .sm\:col-2, .sm\:col-3, .sm\:col-4, .sm\:col-5, .sm\:col-6, .sm\:col-7, .sm\:col-8, .sm\:col-9, .sm\:col-10, .sm\:col-11, .sm\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .sm\:col-6 {
    width: 50%;
  }
  
  .sm\:col-12 {
    width: 100%;
  }
}


@media only screen and (min-width: 768px)
{
  .md\:flex {
    display: flex!important;
  }
  .md\:col-1, .md\:col-2, .md\:col-3, .md\:col-4, .md\:col-5, .md\:col-6, .md\:col-7, .md\:col-8, .md\:col-9, .md\:col-10, .md\:col-11, .md\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .md\:col-1 {
    width: 8.3333%;
  }
  .md\:col-2 {
    width: 16.6667%;
  }
  .md\:col-3 {
    width: 25%;
  }
  .md\:col-4 {
    width: 33.3333%;
  }
  .md\:col-5 {
    width: 41.6667%;
  }
  .md\:col-6 {
    width: 50%;
  }
  .md\:col-7 {
    width: 58.3333%;
  }
  .md\:col-8 {
    width: 66.6667%;
  }
  .md\:col-9 {
    width: 75%;
  }
  .md\:col-10 {
    width: 83.3333%;
  }
  .md\:col-11 {
    width: 91.6667%;
  }
  .md\:col-12 {
    width: 100%;
  }
  
}

@media only screen and (min-width: 992px){
  .logout{
    text-decoration: none;
    position: absolute;
    right: 60px;
    display: none;
  }
  .logoutD{
    text-decoration: none;
    position: absolute;
    right: 60px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
  }
  /* Primeflex classes */
  .lg\:flex {
    display: flex!important;
  }
  .lg\:col-1, .lg\:col-2, .lg\:col-3, .lg\:col-4, .lg\:col-5, .lg\:col-6, .lg\:col-7, .lg\:col-8, .lg\:col-9, .lg\:col-10, .lg\:col-11, .lg\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  
  .lg\:col-1 {
    width: 8.3333%;
  }
  .lg\:col-2 {
    width: 16.6667%;
  }
  .lg\:col-3 {
    width: 25%;
  }
  .lg\:col-4 {
    width: 33.3333%;
  }
  .lg\:col-5 {
    width: 41.6667%;
  }
  .lg\:col-6 {
    width: 50%;
  }
  .lg\:col-7 {
    width: 58.3333%;
  }
  .lg\:col-8 {
    width: 66.6667%;
  }
  .lg\:col-9 {
    width: 75%;
  }
  .lg\:col-10 {
    width: 83.3333%;
  }
  .lg\:col-11 {
    width: 91.6667%;
  }
  .lg\:col-12 {
    width: 100%;
  }
  
}

