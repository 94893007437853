

.loaderOverlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2000;
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
      stroke: #f9c119;
  }
  40% {
      stroke: #f9c119;
  }
  66% {
      stroke: #f9c119;
  }
  80%,
  90% {
      stroke: #f9c119;
  }
}