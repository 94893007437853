body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif; */
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #444;
    font-size: 14px;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  *:focus {
    box-shadow: none !important;
  }
  p{line-height: 1.5;}
  .container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 10px;
  }
  .grid-container {
    max-width: 1064px;
    padding: 0 0.5rem;
  }
  .p-component {
    font-family: "Roboto", sans-serif;
  }
  .p-disabled, .p-component:disabled{
    opacity: 1;
    width: 100%;
    resize: none;
    border-radius: 0;
    border: 0;
    display: block; 
    padding: 0;
    cursor: auto !important;
    pointer-events: auto;
    font-size: 14px;
    line-height: 1.5;
    background-color: transparent;
    margin: 10px 0;
    height: auto;
  }
  .p-image-preview-container{
    width: 100%;
  }
  .p-image-toolbar{z-index: 100;}
.middleContainer {
    /* margin-top: 80px; */
  }
.L2 {
    margin-top: 24px;
  } 
  
  .btnLarge {
    padding: 15px 85px;
  }

  .carouselWrapper{position: relative;max-width: 1240px;margin: 0 auto;}
  .carouselWrapper .react-multi-carousel-list{}
  .react-multi-carousel-list{flex-wrap: wrap;}
  .react-multiple-carousel__arrow{height: 25%;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;}
  .react-multiple-carousel__arrow:before{color: #f9c119;  font-size: 16px;width: 30px;height: 30px;line-height: 2;display: inline-block;transition: all 0.3s ease-in-out;}

  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right{z-index: 10;}
  .react-multiple-carousel__arrow:before {
    border: 0;
    border-radius: 50%;
    background: url(images/left_arrow.png);
    background-repeat: no-repeat;
    color: transparent;
    height: 40px;
  }
  .react-multiple-carousel__arrow--right:before {
    background: url(images/right_arrow.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 45px;
  }

  .part2 .react-multiple-carousel__arrow--right:before,
  .aboutus .react-multiple-carousel__arrow--right:before,
  .announcement .react-multiple-carousel__arrow--right:before,
  .galleryWrapper .react-multiple-carousel__arrow--right:before,
  .carouselPopUp .react-multiple-carousel__arrow--right:before
  {
    background:url(images/right_arrow_blue.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 45px;
  }

  .part2 .react-multiple-carousel__arrow--left:before,
  .aboutus .react-multiple-carousel__arrow--left:before,
  .announcement .react-multiple-carousel__arrow--left:before,
  .galleryWrapper .react-multiple-carousel__arrow--left:before,
  .carouselPopUp .react-multiple-carousel__arrow--left:before
  {
    background:url(images/left_arrow_blue.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 40px;
  }
  
  .mainCarousel .react-multiple-carousel__arrow{height: 100%;width: 60px;}

  .carouselWrapper .react-multiple-carousel__arrow--left{left: 10px;}
  .carouselWrapper .react-multiple-carousel__arrow--right{right: 10px;}
  .react-multiple-carousel__arrow:hover{background-color: transparent;}
  .controlButtonsSmall .p-button.p-button-icon-only.p-button-rounded {
    width: 25px;
    height: 25px;
    padding: 5px;
    margin: 0 5px;
  } 
  .controlButtonsSmall .p-button.p-button-sm .p-button-icon{font-size: 12px;}

  .p-fluid .p-button{
    width: auto;
  }

  .navigation > div {
    justify-content: flex-start !important;
  }
  .navFont{
    font-size: 18px;
  }
